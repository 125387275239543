<template>
  <v-dialog
    v-if="user"
    :value="active"
    width="500"
    :dark="settings.dark || dark"
    persistent
  >
    <v-card style="border-radius: 10px" max-height="500">
      <v-card-title class="pt-2">
        Account{{ allPuzzles ? "🌠" : "" }}
      </v-card-title>
      <template v-if="$vuetify.breakpoint.smAndUp">
        <div
          class="v-card__subtitle pt-1 pb-0"
          :style="{ color: dark ? 'white' : 'black' }"
        >
          <span style="float: left">
            {{ user.email }}
          </span>
          <span style="float: right">
            <strong>
              {{ completedBlockables }}
              {{ completedBlockables == 1 ? "Blockable" : "Blockables" }}
            </strong>
            completed!
          </span>
        </div>
        <div
          class="v-card__subtitle py-0"
          :style="{ color: dark ? 'white' : 'black' }"
        >
          <span style="float: left">
            Player since {{ getFullDate(user.created_at.substring(0, 10)) }}
          </span>
          <span style="float: right">
            {{ completionBreakdown }}
          </span>
        </div>
      </template>
      <template v-else>
        <div
          class="v-card__subtitle pt-1 pb-0"
          :style="{ color: dark ? 'white' : 'black' }"
        >
          {{ user.email }}
          <br />
          <strong>
            {{ completedBlockables }}
            {{ completedBlockables == 1 ? "Blockable" : "Blockables" }}
          </strong>
          completed!
          <br />
          {{ completionBreakdown }}
        </div>
      </template>
      <v-card-text style="min-height: 300px; overflow-y: hidden">
        <v-tabs id="accounts-tabs" grow :dark="dark" v-model="tab">
          <v-tab
            :style="{ color: dark ? 'white !important' : 'black !important' }"
            href="#stats"
          >
            Stats
          </v-tab>
          <!-- <v-tab
            :style="{ color: dark ? 'white !important' : 'black !important' }"
            href="#missed"
          >
            <v-badge
              v-if="numMissedPuzzles"
              :color="$colors.red"
              :content="numMissedPuzzles"
            >
              Missed
            </v-badge>
            <template v-else>Missed</template>
          </v-tab> -->
          <v-tab
            :style="{ color: dark ? 'white !important' : 'black !important' }"
            :dark="dark"
            href="#account"
          >
            Actions
          </v-tab>
        </v-tabs>
        <v-tabs-items :dark="dark" v-model="tab">
          <v-tab-item value="stats">
            <v-card class="account-card" :dark="dark" flat>
              <v-card-text>
                <Stats />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item value="missed">
            <v-card class="account-card" :dark="dark" flat>
              <v-card-text>
                <MissedAPuzzle />
              </v-card-text>
            </v-card>
          </v-tab-item> -->
          <v-tab-item value="account">
            <v-card class="account-card" :dark="dark" flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdGub4LlocGA-z7FiAEfpc1yHbLOj4nXr9x0wEH1uzwu0Ewbg/viewform"
                      target="_blank"
                    >
                      <v-btn block :color="$colors.yellow" class="black--text">
                        Submit Feedback
                      </v-btn>
                    </a>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <router-link to="/privacy-policy">
                      <v-btn block :color="$colors.green" class="black--text">
                        Read the Privacy Policy
                      </v-btn>
                    </router-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      block
                      @click="signout"
                      :color="$colors.blue"
                      class="black--text"
                    >
                      Sign Out
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-dialog v-model="dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :color="$colors.red"
                          block
                          class="black--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Delete Account
                        </v-btn>
                      </template>

                      <v-card :dark="dark" style="overflow-y: hidden">
                        <v-card-title>Delete your account?</v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <p style="font-size: 16px" class="text-center">
                                Are you sure you want to delete your account and
                                all your stats?
                                <br />
                                <br />
                                This action cannot be undone.
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-row>
                            <v-col cols="6">
                              <v-btn
                                :color="$colors.red"
                                block
                                @click="delete_user"
                              >
                                YES
                              </v-btn>
                            </v-col>
                            <v-col cols="6">
                              <v-btn block text @click="dialog = false">
                                NO
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn text :dark="dark" @click="close">Close</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-else :value="active" width="500" :dark="dark" persistent>
    <v-card style="border-radius: 10px">
      <v-card-title class="pt-2">Account</v-card-title>
      <v-card-text style="min-height: 500px">
        <v-tabs fixed-tabs :dark="dark" v-model="tab">
          <v-tab
            :style="{ color: dark ? 'white !important' : 'black !important' }"
            href="#sign-in"
          >
            Sign In
          </v-tab>
          <v-tab
            :style="{ color: dark ? 'white !important' : 'black !important' }"
            href="#create-account"
          >
            Create Account
          </v-tab>
        </v-tabs>
        <v-tabs-items :dark="dark" v-model="tab">
          <v-tab-item value="sign-in">
            <v-card class="account-card" flat :dark="dark">
              <v-card-text>
                <v-text-field
                  v-model="siEmail"
                  label="Email"
                  @keydown.enter.prevent="sendEmail('magiclink')"
                ></v-text-field>
                <v-text-field
                  v-if="emailSent"
                  v-model="verificationCode"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  label="Verification Code"
                  hint="Enter the verification code sent to your email!"
                  @keydown.enter.prevent="signIn"
                ></v-text-field>
                <v-btn
                  v-if="!emailSent"
                  block
                  :color="$colors.blue"
                  class="white--text"
                  :disabled="!validEmail"
                  :loading="loading"
                  @click="sendEmail('magiclink')"
                >
                  Send verification code
                </v-btn>
                <v-btn
                  v-else-if="!showPrivacyPolicyOnSignIn"
                  block
                  :color="$colors.blue"
                  class="white--text"
                  :disabled="!validEmail || !verificationCode"
                  :loading="loading"
                  @click="handleVerificationCode('magiclink')"
                >
                  Sign In
                </v-btn>
                <template v-if="showPrivacyPolicyOnSignIn && !checkboxLoading">
                  <p>
                    Our records indicate you have not agreed to our privacy
                    policy. Please do so to continue.
                  </p>
                  <v-checkbox v-model="acknowledged">
                    <template v-slot:label>
                      <div>
                        I acknowledge that I have read and agree to the
                        <a
                          target="_blank"
                          href="/privacy-policy"
                          style="
                            text-decoration: underline;
                            color: var(--blue) !important;
                            font-weight: 700;
                          "
                          @click.stop
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </template>
                  </v-checkbox>
                </template>
                <v-row v-else-if="showPrivacyPolicyOnSignIn && checkboxLoading">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-progress-circular indeterminate :color="$colors.blue" />
                  </v-col>
                </v-row>
                <p class="text-center pt-4" v-if="emailSent">
                  Blockables has sent a verification code to the email you
                  entered. Enter that code into the box above.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="create-account">
            <v-card class="account-card" flat :dark="dark">
              <v-card-text>
                <v-text-field
                  v-model="siEmail"
                  label="Email"
                  @keydown.enter.prevent="sendEmail"
                ></v-text-field>
                <v-checkbox v-model="acknowledged">
                  <template v-slot:label>
                    <div>
                      I acknowledge that I have read and agree to the
                      <a
                        target="_blank"
                        href="/privacy-policy"
                        style="
                          text-decoration: underline;
                          color: var(--blue) !important;
                          font-weight: 700;
                        "
                        @click.stop
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </template>
                </v-checkbox>
                <v-text-field
                  v-if="emailSent"
                  v-model="verificationCode"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  label="Verification Code"
                  hint="Enter the verification code sent to your email!"
                  @keydown.enter.prevent="signIn"
                />
                <v-btn
                  v-if="!emailSent"
                  block
                  :color="$colors.blue"
                  class="white--text"
                  :disabled="!acknowledged || !validEmail"
                  :loading="loading"
                  @click="sendEmail('signup')"
                >
                  Send verification code
                </v-btn>
                <v-btn
                  v-else
                  block
                  :color="$colors.blue"
                  class="white--text"
                  :disabled="!acknowledged || !validEmail || !verificationCode"
                  :loading="loading"
                  @click="handleVerificationCode('signup')"
                >
                  Create Account
                </v-btn>
                <p class="text-center pt-4" v-if="emailSent">
                  Blockables has sent a verification code to the email you
                  entered. Enter that code into the box above.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn text :dark="dark" @click="close">Close</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import MissedAPuzzle from "@/components/MissedAPuzzle.vue";
import Stats from "@/components/Stats.vue";

export default {
  data: () => ({
    acknowledged: false,
    checkboxLoading: false,
    confirmUser: null,
    dialog: false,
    emailSent: false,
    loading: false,
    siEmail: "",
    showPrivacyPolicyOnSignIn: false,
    tab: 0,
    type: "magiclink",
    verificationCode: "",
  }),
  components: {
    //MissedAPuzzle,
    Stats,
  },
  computed: {
    active() {
      return this.$store.state.USER.dialogs.account;
    },
    allPuzzles() {
      return true;
    },
    completedBlockables() {
      return this.$store.state.USER.stats.length;
    },
    completionBreakdown() {
      let counts = {
        crown: 0,
        sax: 0,
        tada: 0,
      };
      this.$store.state.USER.stats.forEach(s => {
        counts[s.symbol]++;
      });
      let cb = "";
      if (counts.crown) {
        cb += `${counts["crown"]} 👑 | `;
      }
      if (counts.sax) {
        cb += `${counts["sax"]} 🎷 | `;
      }
      if (counts.tada) {
        cb += `${counts["tada"]} 🎉`;
      }
      if ((counts.crown || counts.sax) && !counts.tada) {
        cb = cb.slice(0, -2);
      }
      return cb;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    // numMissedPuzzles() {
    //   return this.$store.state.USER.missedPuzzles.filter(
    //     mp => mp.symbol === null
    //   ).length;
    // },
    settings() {
      return this.$store.state.USER.settings;
    },
    user() {
      return this.$store.state.sb_user;
    },
    validEmail() {
      if (
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.siEmail)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    async acknowledged() {
      if (this.showPrivacyPolicyOnSignIn && this.acknowledged) {
        this.checkboxLoading = true;
        await this.$db.from("user_mgmt").insert({
          privacy_policy_version_agreed_to: 1,
          user_email: this.siEmail.toLowerCase(),
          marked_for_deletion: false,
        });
        setTimeout(() => {
          this.checkboxLoading = false;
          this.$store.commit("USER_SET", this.confirmUser);
          this.$store.dispatch("USER/GET_STATS_FROM_DB", this.confirmUser);
        }, 3000);
      }
    },
    tab() {
      this.acknowledged = false;
      this.emailSent = false;
      this.siEmail = "";
      this.type = this.tab == 0 ? "magiclink" : "signup";
      this.verificationCode = "";
    },
    verificationCode() {
      if (this.verificationCode.length == 6) {
        this.handleVerificationCode(this.type);
      }
    },
  },
  methods: {
    close() {
      this.$store.commit("USER/ACCOUNT_DIALOG_SET", false);
    },
    reset() {
      this.acknowledged = false;
      this.checkboxLoading = false;
      this.confirmUser = null;
      this.emailSent = false;
      this.siEmail = "";
      this.showPrivacyPolicyOnSignIn = false;
      this.tab = 0;
      this.type = "magiclink";
      this.verificationCode = "";
    },
    getFullDate(d) {
      return new Date(d).toUTCString().substring(0, 16);
    },
    async delete_user() {
      await this.$db
        .from("user_mgmt")
        .update({ marked_for_deletion: true })
        .eq("user_email", this.user.email.toLowerCase());
      await this.signout();
    },
    async sendEmail(type) {
      let proceed =
        (type == "signup" && this.acknowledged && this.validEmail) ||
        (type == "magiclink" && this.validEmail);
      this.type = type;
      if (proceed) {
        this.loading = true;
        let r = await this.$db.auth.signInWithOtp({
          email: this.siEmail.toLowerCase(),
          options: {
            shouldCreateUser: type == "signup",
          },
        });
        if (r.error) {
          if (r.error.message == "Signups not allowed for otp") {
            this.$store.commit("USER/SNACKBAR_SET", {
              active: true,
              text: `No user found for email ${this.siEmail}. Please use "CREATE ACCOUNT" to create an account.`,
              type: "bad",
            });
            this.loading = false;
            return;
          }
        }
        this.emailSent = true;
        this.loading = false;
        this.$store.commit("USER/SNACKBAR_SET", {
          active: true,
          text: `A verification code has been sent to ${this.siEmail}. Enter it in the Verification Code box to sign in!`,
          type: "success",
        });
        setTimeout(() => {
          this.$store.commit("USER/SNACKBAR_SET", {
            active: false,
            text: "",
            type: "success",
          });
        }, 10000);
      }
    },
    async handleVerificationCode(type) {
      this.loading = true;
      let proceed =
        this.verificationCode &&
        this.validEmail &&
        ((type == "signup" && this.acknowledged) || type == "magiclink");
      if (proceed) {
        const { data, error } = await this.$db.auth.verifyOtp({
          email: this.siEmail.toLowerCase(),
          token: this.verificationCode,
          type: type,
        });
        if (error) {
          let message = error;
          if (type == "magiclink") {
            message =
              'Blockables could not log you in. If you need to create an account, please select the "CREATE ACCOUNT" option on the "Account" page. If you already have an account, reach out to the Blockables team through the feedback form in the sidebar.';
          } else {
            message =
              "Blockables could not create your account. Please try again later.";
          }
          this.$store.commit("USER/SNACKBAR_SET", {
            active: true,
            type: "bad",
            text: `ERROR: ${message}`,
          });
        }
        if (!error) {
          if (type == "signup") {
            await this.$db.from("user_mgmt").insert({
              privacy_policy_version_agreed_to: 1,
              user_email: this.siEmail.toLowerCase(),
              marked_for_deletion: false,
            });
            this.$store.commit("USER_SET", data.user);
            this.$store.dispatch("USER/GET_STATS_FROM_DB", data.user);
            this.reset();
          } else {
            let r = await this.$db.from("user_mgmt").select();
            if (r.data.length) {
              this.$store.commit("USER_SET", data.user);
              this.$store.dispatch("USER/GET_STATS_FROM_DB", data.user);
              this.reset();
            } else {
              this.showPrivacyPolicyOnSignIn = true;
              this.confirmUser = data.user;
            }
          }
        }
      }
      this.loading = false;
    },
    async signout() {
      this.$store.dispatch("USER/SIGN_OUT");
      this.reset();
      this.close();
    },
  },
};
</script>

<style scoped>
.account-card {
  border-radius: 0;
}
</style>
