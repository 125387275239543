<template>
  <v-card>
    <v-card-title v-if="mode == 'editor'">
      Clue in your Grid ({{ weekday }})
    </v-card-title>
    <v-card-title v-else>Clue in your Grid</v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Selector</th>
              <th class="text-center">Number</th>
              <th class="text-center">Color</th>
              <th class="text-center">Count</th>
              <th class="text-left">Word</th>
              <th class="text-left">Suggestion</th>
              <th class="text-left">Clue</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(count, color) in colorCounts"
              :id="`clue-${color}`"
              :key="color"
            >
              <td class="text-center">
                <v-icon
                  x-large
                  tabindex="-1"
                  :color="
                    targeting.color == color ||
                    numberPlacements[getOrdinalFromColor(color) + 1]
                      ? color
                      : 'gray'
                  "
                  @click="setTargeting(color)"
                >
                  {{
                    numberPlacements[getOrdinalFromColor(color) + 1]
                      ? icons.check
                      : icons.target
                  }}
                </v-icon>
              </td>
              <td class="text-center">{{ getOrdinalFromColor(color) + 1 }}</td>
              <td>
                <v-sheet height="25" width="25" :color="color"></v-sheet>
              </td>
              <td class="text-center">
                {{
                  words[getOrdinalFromColor(color) + 1]
                    ? words[getOrdinalFromColor(color) + 1].length
                    : 0
                }}/{{ count }}
              </td>
              <td>
                <v-text-field
                  v-model="words[getOrdinalFromColor(color) + 1]"
                  class="uppercase-input"
                  label="Word"
                  :maxlength="count"
                  @input="getSuggestions(getOrdinalFromColor(color))"
                />
              </td>
              <td>
                <v-select
                  style="width: 200px !important"
                  :items="suggestions[getOrdinalFromColor(color)]"
                  :disabled="!enableSuggestions(color)"
                  @change="acceptSuggestion($event, getOrdinalFromColor(color))"
                />
              </td>
              <td>
                <v-text-field
                  v-model="clues[getOrdinalFromColor(color) + 1]"
                  label="Clue"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="4">
          <v-btn text @click="$store.commit('CREATOR/DECREMENT_STEP')">
            Back
          </v-btn>
        </v-col>
        <v-col v-if="mode == 'editor'" cols="4">
          <v-btn
            :color="$colors.purple"
            block
            class="white--text"
            @click="pullClues"
          >
            Pull Clues
          </v-btn>
        </v-col>
        <v-spacer v-else />
        <v-col cols="4">
          <v-btn
            :color="$colors.blue"
            :disabled="!enableSubmit"
            block
            class="white--text"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiCheck, mdiTarget } from "@mdi/js";

export default {
  props: ["mode"],
  data: () => ({
    debouncedInput: "",
    icons: {
      check: mdiCheck,
      target: mdiTarget,
    },
    suggestions: {},
    timeout: null,
  }),
  computed: {
    clues: {
      get() {
        return this.$store.state.CREATOR.clues;
      },
      set(v) {
        this.$store.state.CREATOR.clues = v;
      },
    },
    colorCounts() {
      return this.$store.getters["CREATOR/COLOR_COUNTS"](null);
    },
    enableSubmit() {
      let wordOrdinals = Object.keys(this.words);
      let clueOrdinals = Object.keys(this.clues);
      let numberPlacementOrdinals = Object.keys(this.numberPlacements);
      if (numberPlacementOrdinals.length == 0) return false;
      let wordsPopulated = wordOrdinals.every(key => {
        return this.words[key];
      });
      let cluesPopulated = clueOrdinals.every(key => {
        return this.clues[key];
      });
      return (
        wordsPopulated &&
        cluesPopulated &&
        wordOrdinals.length == numberPlacementOrdinals.length &&
        clueOrdinals.length == numberPlacementOrdinals.length &&
        Object.keys(this.colorCounts).length == numberPlacementOrdinals.length
      );
    },
    grid() {
      return this.$store.state.CREATOR.grid;
    },
    gridColors() {
      return this.$store.state.CREATOR.gridColors;
    },
    numberPlacements: {
      get() {
        return this.$store.state.CREATOR.numberPlacements;
      },
      set(v) {
        this.$store.state.CREATOR.numberPlacements = v;
      },
    },
    puzzleClues() {
      return this.$store.state.PUZZLE.clues;
    },
    puzzlePlacements() {
      return this.$store.state.PUZZLE.numberPlacements;
    },
    targeting() {
      return this.$store.state.CREATOR.targeting;
    },
    weekday() {
      if (this.mode != "editor") return "Monday";
      const weekday = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const d = new Date(this.$route.params.d);
      return weekday[d.getDay() + 1];
    },
    words: {
      get() {
        return this.$store.state.CREATOR.words;
      },
      set(v) {
        this.$store.state.CREATOR.words = v;
      },
    },
  },
  mounted() {
    let initialize = {};
    for (let color in this.colorCounts) {
      let ordinal = this.getOrdinalFromColor(color);
      initialize[ordinal] = null;
    }
    if (this.mode == "editor") {
      initialize = {};
      for (let color in this.colorCounts) {
        let ordinal = this.getOrdinalFromColor(color) + 1;
        initialize[ordinal] = null;
      }
      this.$store.commit("CREATOR/CLUES_SET", { ...initialize });
      this.$store.commit("CREATOR/WORDS_SET", { ...initialize });
    }
  },
  methods: {
    acceptSuggestion(e, ordinal) {
      this.$set(this.clues, ordinal + 1, e);
    },
    enableSuggestions(color) {
      let ordinal = this.getOrdinalFromColor(color);
      return ordinal in this.suggestions && this.suggestions[ordinal].length;
    },
    getOrdinalFromColor(color) {
      return Object.keys(this.colorCounts).indexOf(color);
    },
    getSuggestions(ordinal) {
      let word = this.words[ordinal + 1];

      // Check if first letter of word is correct
      if (word) {
        let firstLetter = word[0];
        let placement = this.numberPlacements[ordinal + 1];
        let numberedLetter = this.grid[placement[0]][placement[1]];
        let numberedColor = this.gridColors[placement[0]][placement[1]];
        if (firstLetter.toUpperCase() != numberedLetter.toUpperCase()) {
          document.getElementById(
            `clue-${numberedColor}`
          ).style.backgroundColor = this.$colors.red;
          return;
        }
        document.getElementById(`clue-${numberedColor}`).style.backgroundColor =
          "#FFF";
      }

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const { data, error } = await this.$db.rpc("get_previous_clues", {
          word: word,
        });
        if (!error) {
          this.$set(
            this.suggestions,
            ordinal,
            data.map(d => Object.values(d)[0])
          );
        }
      }, 300);
    },
    pullClues() {
      // this.clues = this.puzzleClues.map(p => p.clue);
      // this.numberPlacements = this.puzzlePlacements;
      // this.words = this.puzzleClues.map(p => p.reveal);
    },
    reorganizeClues() {
      let newClues = [];
      let newOrdinal = 1;
      let newNumberPlacements = {};
      for (let r = 0; r < 5; r++) {
        for (let c = 0; c < 5; c++) {
          for (const k in this.numberPlacements) {
            let v = this.numberPlacements[k];
            if (v[0] == r && v[1] == c) {
              newNumberPlacements[newOrdinal] = [r, c];
              newClues.push({
                color: this.gridColors[r][c],
                clue: this.clues[k],
                ordinal: newOrdinal,
                reveal: this.words[k],
              });
              newOrdinal++;
            }
          }
        }
      }
      this.numberPlacements = newNumberPlacements;
      this.clues = newClues;
    },
    setTargeting(color) {
      if (this.targeting.color) {
        this.$store.commit("CREATOR/TARGETING_SET", {
          color: null,
          ordinal: null,
        });
      } else {
        this.$store.commit("CREATOR/TARGETING_SET", {
          color: color,
          ordinal: this.getOrdinalFromColor(color) + 1,
        });
      }
    },
    submit() {
      this.reorganizeClues();
      this.$store.commit("CREATOR/INCREMENT_STEP");
    },
  },
};
</script>

<style scoped>
.uppercase-input >>> input {
  text-transform: uppercase;
}
</style>
