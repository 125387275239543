<template>
  <v-navigation-drawer app v-model="drawer" right temporary :dark="dark">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <router-link to="/">
            <div class="d-flex align-center">
              <v-img
                v-if="$ark.active"
                alt="Blockables logo"
                class="shrink mr-2"
                contain
                :src="require('@/assets/blockables-arkadium.png')"
                transition="scale-transition"
                width="32px"
              />
              <v-img
                v-else
                alt="Blockables logo"
                class="shrink mr-2"
                contain
                :src="require('@/assets/blockables-square.png')"
                transition="scale-transition"
                width="32px"
              />
              <h1
                tabindex="-1"
                :style="{
                  color: dark ? 'white' : 'black !important',
                  'font-size': '32px',
                }"
              >
                Blockables
              </h1>
            </div>
          </router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <template v-if="!$ark.active">
      <v-list nav dense>
        <v-list-item three-line v-if="user" @click="viewAccount">
          <v-list-item-content>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
            <v-list-item-subtitle>
              Player since {{ getFullDate(user.created_at.substring(0, 10)) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ completedBlockables }}
              {{ completedBlockables == 1 ? "Blockable" : "Blockables" }}
              completed!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-btn v-else :color="$colors.blue" dark block @click="viewAccount">
          Sign In
        </v-btn>
      </v-list>
      <v-divider></v-divider>
    </template>
    <v-list nav dense flat>
      <v-list-item-group active-class="text--accent-4">
        <v-list-item v-if="user" @click="viewAccount">
          <v-list-item-icon>
            <v-icon>
              {{ icons.account }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Stats & Account Options</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="navLink in topNavLinks"
          :key="navLink.text"
          @click="handleClick(navLink)"
        >
          <v-list-item-icon>
            <v-icon>
              {{ navLink.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ navLink.text }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$ark.active || allPuzzles" @click="playPastPuzzles">
          <v-list-item-icon>
            <v-icon>
              {{ icons.history }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Play Past Puzzles</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider />
    <v-list
      class="bottom-list"
      :style="{ backgroundColor: dark ? '#363636' : 'white' }"
      nav
      dense
      flat
    >
      <v-list-item-group active-class="text--accent-4">
        <v-list-item
          v-for="navLink in bottomNavLinks"
          :key="navLink.text"
          @click="handleClick(navLink)"
          :class="{ showcase: navLink?.showcase }"
        >
          <v-list-item-icon>
            <v-icon>
              {{ navLink.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ navLink.text }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user" @click="signout">
          <v-list-item-icon>
            <v-icon>
              {{ icons.signout }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiAccount,
  mdiArrowRight,
  mdiCommentQuote,
  mdiCog,
  mdiHelpCircleOutline,
  mdiHistory,
  mdiLightbulbOn,
  mdiLogout,
  mdiMailbox,
  mdiMovieOpenPlay,
} from "@mdi/js";

export default {
  data: () => ({
    drawer: false,
    icons: {
      account: mdiAccount,
      history: mdiHistory,
      right: mdiArrowRight,
      signout: mdiLogout,
    },
    menu: false,
    pppActive: false,
    siEmail: "",
    topNavLinks: [
      {
        icon: mdiHelpCircleOutline,
        method: "showInstructions",
        text: "Read Instructions",
      },
      {
        icon: mdiMovieOpenPlay,
        open: "https://youtu.be/Vu4DZZ4CJgI",
        text: "Watch Instructions",
      },
      {
        icon: mdiLightbulbOn,
        text: "Play Tutorial",
        to: "/how-to-play",
      },
    ],
    bottomNavLinks: [
      {
        icon: mdiCog,
        method: "showSettings",
        text: "Settings",
      },
    ],
  }),
  props: ["show"],
  computed: {
    allPuzzles() {
      return true;
    },
    completedBlockables() {
      return this.$store.state.USER.stats.length;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    user() {
      return !this.$ark.active && this.$store.state.sb_user;
    },
  },
  async mounted() {
    window.addEventListener("resize", this.getDocumentHeight);
    this.getDocumentHeight();
    if (this.$ark.active) return;
    if (this.user) {
      this.$store.dispatch("USER/GET_STATS_FROM_DB", this.user);
    }
    let feedback = {
      icon: mdiCommentQuote,
      open: "https://docs.google.com/forms/d/e/1FAIpQLSdGub4LlocGA-z7FiAEfpc1yHbLOj4nXr9x0wEH1uzwu0Ewbg/viewform",
      text: "Submit Feedback",
    };
    this.bottomNavLinks.unshift(feedback);
    let newsletter = {
      icon: mdiMailbox,
      open: "https://improvisational-thinking.ck.page/blockables",
      text: "Join the Newsletter!",
      showcase: true,
    };
    this.bottomNavLinks.unshift(newsletter);
  },
  methods: {
    getDocumentHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    },
    getFullDate(d) {
      return new Date(d).toUTCString().substring(0, 16);
    },
    handleClick(nl) {
      if ("method" in nl) {
        this.group = null;
        this.drawer = false;
        this[nl.method]();
      } else if ("to" in nl) {
        this.$router.push("/how-to-play");
      } else if ("open" in nl) {
        window.open(nl.open, "_blank");
      }
    },
    playPastPuzzles() {
      this.drawer = false;
      this.$store.commit("USER/PLAY_PAST_PUZZLES_SET", true);
    },
    showInstructions() {
      this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", true);
    },
    showSettings() {
      this.$store.commit("USER/SETTINGS_DIALOG_SET", true);
    },
    async signout() {
      this.$store.dispatch("USER/SIGN_OUT");
      this.$router.push("/");
    },
    viewAccount() {
      this.$store.commit("USER/ACCOUNT_DIALOG_SET", true);
      this.drawer = false;
    },
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.drawer = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
:root {
  --doc-height: 100%;
}

.v-navigation-drawer {
  padding: 0;
  margin: 0;
  height: var(--doc-height) !important;
}

.bottom-list {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.showcase {
  background: var(--yellow);
  background: linear-gradient(317deg, var(--blue), var(--yellow), var(--red));
  background-size: 400% 200%;

  -webkit-animation: bookGradient 10s ease infinite;
  -moz-animation: bookGradient 10s ease infinite;
  animation: bookGradient 10s ease infinite;
}

@-webkit-keyframes bookGradient {
  0% {
    background-position: 0% 61%;
  }
  50% {
    background-position: 100% 40%;
  }
  100% {
    background-position: 0% 61%;
  }
}
@-moz-keyframes bookGradient {
  0% {
    background-position: 0% 61%;
  }
  50% {
    background-position: 100% 40%;
  }
  100% {
    background-position: 0% 61%;
  }
}
@keyframes bookGradient {
  0% {
    background-position: 0% 61%;
  }
  50% {
    background-position: 100% 40%;
  }
  100% {
    background-position: 0% 61%;
  }
}

.showcase,
.showcase >>> .v-icon {
  color: black !important;
}
</style>
