import Vue from "vue";

export default {
  COLOR_CELL(state, payload) {
    let subArray = state.gridColors[payload.r];
    subArray[payload.c] = state.color;
    state.gridColors.splice(payload.r, 1, subArray);
  },
  CLEAR_GRID(state) {
    for (var i = 0; i < 5; i++) {
      let whiteRow = Array(5).fill("white");
      state.gridColors.splice(i, 1, whiteRow);
    }
  },
  DECREMENT_STEP(state) {
    state.step--;
  },
  EDIT_CLUES_SET(state, clues) {
    clues.forEach(clue => {
      Vue.set(state.clues, clue.ordinal, clue.clue);
      Vue.set(state.words, clue.ordinal, clue.reveal);
    });
  },
  GRID_COLORS_SET(state, colors) {
    state.gridColors = colors;
  },
  INCREMENT_STEP(state) {
    state.step++;
  },
  NUMBER_PLACEMENTS_SET(state, np) {
    state.numberPlacements = np;
  },
  STEP_SET(state, step) {
    state.step = step;
  },
};
