<template>
  <v-dialog :dark="dark" :value="active" width="500" persistent>
    <v-card style="overflow: hidden">
      <v-row>
        <v-spacer />
        <v-col cols="10" class="justify-center text-center">
          <!-- <v-icon
            class="win-close-icon"
            @click="$store.commit('USER/EXAMPLE_WIN_DIALOG_SET', false)"
          >
            {{ icons.close }}
          </v-icon> -->
          <h1 style="font-size: 100px" class="swing">🎉</h1>
          <br />
          <p style="font-size: 30px; margin-bottom: 20px">
            You solved an example Blockable!
          </p>
          <p>
            Example Blockable Answer:
            <br />
            <strong>{{ phrase }}</strong>
          </p>
          <template>
            <ExampleLoader />
          </template>
          <p></p>
        </v-col>
        <v-spacer />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClipboardOutline, mdiClipboardCheck, mdiClose } from "@mdi/js";

import ExampleLoader from "@/components/example/ExampleLoader.vue";

export default {
  data: () => ({
    icons: {
      check: mdiClipboardCheck,
      close: mdiClose,
      outline: mdiClipboardOutline,
    },
  }),
  components: { ExampleLoader },
  computed: {
    active() {
      return this.$store.state.USER.dialogs.exampleWin;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    phrase() {
      return this.$store.state.PUZZLE.phrase;
    },
  },
  watch: {
    active: {
      async handler() {
        if (this.active) {
          this.$analytics.logEvent("tutorial_complete");
          this.$store.commit("USER/PLAYING_SET", false);
          this.$store.commit("USER/TIMER_STOP");
        } else {
          this.clipboardIcon = "outline";
        }
      },
    },
  },
};
</script>

<style scoped>
.swing {
  margin: 10px 0 -10px 0;
  animation: swing ease-in-out 1s infinite alternate;
}

.win-close-icon {
  position: absolute;
  top: 25px;
  right: 40px;
  z-index: 500;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
</style>
