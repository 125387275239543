<template>
  <v-card
    :dark="dark"
    flat
    style="background: none; height: 250px"
    v-if="user && stats.length"
  >
    <v-card-text id="custom-scroll">
      <v-list v-for="item in stats" :key="item.ordinal">
        <v-list-item dense class="px-0" three-line>
          <v-list-item-avatar>
            <span style="font-size: 1.5rem">
              {{ emojiMap[item.symbol] }}
            </span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ getFullDate(item.date) }}</strong>
              <strong style="float: right" v-if="item.timed">
                {{ $store.getters["USER/GET_TIME"](item.t) }}
              </strong>
            </v-list-item-title>

            <v-list-item-subtitle>
              <div>
                <span>{{ displaySpecialMessage(item) }}</span>
                <span style="float: right">
                  {{ item.checks }} ✔️ {{ item.letters_revealed }} 👁️
                </span>
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <p id="marquee" v-if="$vuetify.breakpoint.xsOnly">
                {{ item.phrase.toUpperCase() }}
              </p>
              <p v-else>{{ item.phrase.toUpperCase() }}</p>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
  <p class="text-center" style="font-size: 16 px" v-else>
    You have no stats! Complete a Blockable to make one.
  </p>
</template>

<script>
export default {
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    emojiMap() {
      return {
        crown: "👑",
        sax: "🎷",
        tada: "🎉",
      };
    },
    stats() {
      return this.$store.state.USER.stats;
    },
    user() {
      if (this.$ark.active) return null;
      try {
        let localStorageUser = JSON.parse(
          localStorage.getItem("sb-cterwiozjbjdeoazbzdd-auth-token")
        );
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = this.$store.state.sb_user;
        return storeUser || localStorageUser || null;
      } catch {
        return null;
      }
    },
  },
  methods: {
    displaySpecialMessage(stat) {
      if (stat.percentile)
        return `Top ${stat.percentile}% of Unassisted Solves Today!`;
      if (stat.letters_revealed > 10) return null;
      if (stat.symbol == "crown")
        return ["Regal!", "Royal!", "Majestic!"][Math.floor(Math.random() * 3)];
      if (stat.checks == 0 && stat.letters_revealed == 0) {
        return "Perfect!";
      }
      let tiers = ["Sax-tastic!", "Excellent!", "Groovy!"];
      let tier = Math.ceil(stat.letters_revealed / 5);
      return tiers[tier];
    },
    getFullDate(d) {
      return new Date(d).toUTCString().substring(0, 16);
    },
  },
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
#custom-scroll {
  scrollbar-width: auto;
  scrollbar-color: var(--purple) rgba(0, 0, 0, 0);
  overflow-y: scroll;
}

/* Chrome, Edge, and Safari */
#custom-scroll::-webkit-scrollbar {
  width: 4px;
}

#custom-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

#custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--purple);
  border-radius: 4px;
  border: 0;
}

#marquee {
  -moz-animation: marquee 10s linear infinite;
  -webkit-animation: marquee 10s linear infinite;
  animation: marquee 10s linear infinite;
  white-space: nowrap;
}
@-moz-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-150%);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-150%);
  }
}
@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-150%);
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
  }
}
</style>
