var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:({
    backgroundColor: _vm.dark ? 'rgb(40, 40, 40)' : 'inherit',
  }),attrs:{"fill-height":"","fluid":""}},[_c('BlockablesNavbar',{attrs:{"show":_vm.showNavDrawer}}),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-row',[_c('v-col',{staticClass:"pr-6 d-flex align-center justify-end",attrs:{"cols":"12"}},[(!_vm.$ark.active)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.commit('USER/ACCOUNT_DIALOG_SET', true)}}},[_c('v-icon',{attrs:{"color":_vm.dark ? '#fafafa' : _vm.$colors.blue}},[_vm._v(" "+_vm._s(_vm.icons.account)+" ")])],1):_vm._e(),_c('v-btn',{staticClass:"pl-2",attrs:{"icon":""},on:{"click":function($event){_vm.showNavDrawer += 1}}},[_c('v-icon',{attrs:{"aria-label":"Show menu","tabindex":"-1","color":_vm.dark ? '#fafafa' : _vm.$colors.blue}},[_vm._v(" "+_vm._s(_vm.icons.menu)+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[(_vm.$ark.active)?_c('v-img',{attrs:{"aspect-ratio":1 / 1,"height":"100","alt":"Blockables logo","contain":"","src":require('@/assets/blockables-arkadium.png'),"transition":"scale-transition"}}):_c('v-img',{attrs:{"aspect-ratio":1 / 1,"height":"100","alt":"Blockables logo","contain":"","src":require('@/assets/blockables-square.png'),"transition":"scale-transition"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center",class:_vm.author ? 'pb-2' : '',attrs:{"cols":"12"}},[_c('span',{style:({
              color: _vm.dark ? 'white' : 'black !important',
            })},[_c('h1',{staticClass:"text-center"},[_vm._v("Blockables")]),_c('p',{staticClass:"text-center",class:_vm.author ? 'mb-0' : '',staticStyle:{"line-height":"1.2"}},[_c('strong',[_vm._v("#"+_vm._s(_vm.puzzleNumber)+":")]),_vm._v(" "+_vm._s(_vm.$route.name == "Home" ? _vm.todayHumanReadable : _vm.humanReadable(_vm.$route.params.d))+" "),(_vm.author)?[_c('br'),_c('em',{staticStyle:{"font-size":"12px"}},[_vm._v("By "+_vm._s(_vm.author))])]:_vm._e()],2)])])],1),(_vm.wins.includes(_vm.puzzleNumber))?_c('v-row',[_c('v-col',{staticClass:"py-0 d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0",style:({
              color: _vm.dark ? 'white' : 'black !important',
            })},[_vm._v(" Tomorrow's puzzle in: "),_c('b',[_vm._v(_vm._s(_vm.timeRemaining)+"...")])])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text",attrs:{"block":"","color":_vm.$colors.blue,"dark":_vm.dark,"disabled":_vm.wins.includes(_vm.puzzleNumber)},on:{"click":function($event){return _vm.play()}}},[_vm._v(" "+_vm._s(_vm.wins.includes(_vm.puzzleNumber) ? "Puzzle solved!" : _vm.$route.name == "Home" ? "Play Today's Puzzle" : `Play Puzzle #${_vm.puzzleNumber}`)+" ")])],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"10"}},[_c('PlayPastPuzzlesToggle')],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"6"}},[(_vm.$ark.active)?_c('v-btn',{attrs:{"plain":"","dark":_vm.dark},nativeOn:{"click":function($event){return _vm.goToTutorial.apply(null, arguments)}}},[_vm._v(" Tutorial ")]):_c('v-btn',{attrs:{"plain":"","dark":_vm.dark},nativeOn:{"click":function($event){return _vm.$store.commit('USER/INSTRUCTIONS_DIALOG_SET', true)}}},[_vm._v(" Instructions ")])],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"plain":"","dark":_vm.dark},on:{"click":function($event){return _vm.$store.commit('USER/SETTINGS_DIALOG_SET', true)}}},[_vm._v(" Settings ")])],1)],1)],1),_c('v-spacer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }