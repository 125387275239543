import Vue from "vue";
import VueRouter from "vue-router";

import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Creator from "../views/Creator.vue";
import Editor from "../views/Editor.vue";
import Example from "../views/Example.vue";
import Home from "../views/Home.vue";
import NotTodaysPuzzle from "../views/NotTodaysPuzzle.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    meta: { showKeyboard: false },
  },
  {
    path: "/example",
    redirect: "/how-to-play",
  },
  {
    path: "/how-to-play",
    name: "How to Play",
    component: Example,
    meta: { showKeyboard: true },
  },
  {
    path: "/help-video",
    beforeEnter() {
      window.location.href = "https://www.youtube.com/watch?v=Vu4DZZ4CJgI";
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { showKeyboard: true },
  },
  {
    path: "/puzzles/:d",
    name: "Puzzle",
    component: NotTodaysPuzzle,
    meta: { showKeyboard: true },
  },
  // {
  //   path: "/:catchAll(.*)",
  //   component: Home,
  //   meta: { showKeyboard: true },
  // },
];

if (process.env.VUE_APP_ENVIRONMENT === "dev") {
  routes.unshift({
    path: "/book/:d",
    name: "Book",
    component: Editor,
    props: { table_name: "puzzles_book" },
  });
  routes.unshift({ path: "/create", name: "Create", component: Creator });
  routes.unshift({
    path: "/edit/:d",
    name: "Edit",
    component: Editor,
    props: { table_name: "puzzles" },
  });
}

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: routes,
});

export default router;
