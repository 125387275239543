<template>
  <v-card>
    <v-card-title>Phrase Configuration</v-card-title>
    <v-card-subtitle v-if="user?.email">
      You are signed in as {{ user.email }}.
    </v-card-subtitle>
    <v-card-subtitle
      v-else
      class="clickable-alert py-0"
      @click="$store.commit('USER/ACCOUNT_DIALOG_SET', true)"
    >
      You are not signed in! Click to sign in.
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="phrase"
            label="Phrase"
            hint="Phrase must be 25 letters long"
            :color="$colors.blue"
            counter
            :counter-value="counterAlgo"
            persistent-hint
          />
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="autocompletedPhrase"
            :items="phrases"
            item-text="phrase"
            item-value="phrase"
            label="Or pick an existing phrase!"
            :color="$colors.blue"
            counter
            :counter-value="counterAlgo"
            persistent-hint
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn
            class="white--text"
            @click="submit"
            block
            :color="this.$colors.green"
            :disabled="!user"
          >
            Generate!
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="white--text"
            @click="rng"
            block
            :color="this.$colors.purple"
          >
            Random 25!
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="potentialRepeats">
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Keyword</th>
                  <th class="text-left">Phrase</th>
                  <th class="text-left">Ordinal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(repeat, idx) in potentialRepeats" :key="idx">
                  <td>{{ repeat.word }}</td>
                  <td>{{ repeat.phrase }}</td>
                  <td>{{ repeat.ordinal }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    autocompletedPhrase: "",
    direction: "right",
    grid: [
      [null, null, null, null, null],
      [null, null, null, null, null],
      [null, null, null, null, null],
      [null, null, null, null, null],
      [null, null, null, null, null],
    ],
    phrase: "",
    phrases: [],
    potentialRepeats: null,
  }),
  computed: {
    coilPattern() {
      return Math.random() < 0.5 ? "clockwise" : "counterclockwise";
    },
    directionChain() {
      let chains = {
        clockwise: ["up", "right", "down", "left"],
      };
      chains["counterclockwise"] = chains["clockwise"].slice().reverse();
      return chains[this.coilPattern];
    },
    user() {
      if (this.$ark.active) return null;
      try {
        let localStorageUser = JSON.parse(
          localStorage.getItem("sb-cterwiozjbjdeoazbzdd-auth-token")
        );
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = this.$store.state.sb_user;
        return storeUser || localStorageUser || null;
      } catch {
        return null;
      }
    },
  },
  watch: {
    autocompletedPhrase() {
      if (this.autocompletedPhrase) {
        this.phrase = this.autocompletedPhrase;
      }
    },
    async phrase() {
      this.potentialRepeats = null;
      if (this.phrase.replace(/[^a-zA-Z]/g, "").length == 25) {
        let query = this.$db
          .from("puzzles")
          .select("phrase, ordinal")
          .order("ordinal", { ascending: false });
        let words = this.phrase.split(" ");
        words = words.filter(item => item.length >= 3);
        words = words.filter(
          item =>
            !["and", "the", "but", "with", "for", "some"].includes(
              item.toLowerCase()
            )
        );
        words.sort((a, b) => a.toUpperCase().localeCompare(b));
        let ored = "";
        words.forEach(word => {
          ored += `phrase.ilike.%${word}%,`;
        });
        ored = ored.substring(0, ored.length - 1);
        query.or(ored);
        let { data, error } = await query;
        if (!error) {
          let rows = [];
          data.forEach(d => {
            words.forEach(w => {
              if (d.phrase.toUpperCase().includes(w.toUpperCase())) {
                rows.push({
                  word: w,
                  phrase: d.phrase,
                  ordinal: d.ordinal,
                });
              }
            });
          });
          this.potentialRepeats = rows;
        }
      }
    },
  },
  async mounted() {
    const { data, error } = await this.$db.from("phrases").select();
    if (!error) {
      this.phrases = data;
    }
  },
  methods: {
    counterAlgo() {
      if (this.phrase) {
        return (
          this.phrase.replace(/[^a-zA-Z]/g, "").length.toString() + " / 25"
        );
      }
      return "0 / 25";
    },
    checkAvailabilityInDirection(r, c, direction) {
      let rCopy = r;
      let cCopy = c;
      switch (direction) {
        case "up":
          r -= 1;
          break;
        case "down":
          r += 1;
          break;
        case "left":
          c -= 1;
          break;
        case "right":
          c += 1;
          break;
      }
      if (r < 0 || r > 4 || c < 0 || c > 4) {
        return false;
      }
      if (this.grid[r][c]) {
        let prevDirection = this.getDirection(direction, "prev");
        switch (prevDirection) {
          case "up":
            rCopy -= 1;
            break;
          case "down":
            rCopy += 1;
            break;
          case "left":
            cCopy -= 1;
            break;
          case "right":
            cCopy += 1;
            break;
        }
        return { r: rCopy, c: cCopy, switchDirection: false };
      }
      return { r: r, c: c, switchDirection: true };
    },
    getDirection(currentDirection, nextOrPrev) {
      let dc = this.directionChain;
      let index = dc.indexOf(currentDirection);
      if (nextOrPrev == "next") {
        if (index == 3) {
          return this.directionChain[0];
        }
        return dc[index + 1];
      } else {
        if (index == 0) {
          return this.directionChain[3];
        }
        return dc[index - 1];
      }
    },
    insertIntoGrid(r, c, letter) {
      let subArray = this.grid[r];
      subArray[c] = letter;
      this.grid.splice(r, 1, subArray);
    },
    rng() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 25) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      this.phrase = result;
    },
    submit() {
      this.grid = null;
      this.grid = [
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
      ];
      let dir = this.direction;
      let node = {
        r: 2,
        c: 2,
      };
      for (let letter of this.phrase.replace(/[^a-zA-Z]/g, "")) {
        this.insertIntoGrid(node.r, node.c, letter);
        let nextMove = this.checkAvailabilityInDirection(node.r, node.c, dir);
        if (nextMove.switchDirection) {
          dir = this.getDirection(dir, "next");
        }
        node = nextMove;
      }
      this.$store.commit("CREATOR/GRID_DATA_SET", {
        grid: this.grid,
        direction: this.direction,
        coilPattern: this.coilPattern,
        phrase: this.phrase,
      });
      this.$store.commit("CREATOR/INCREMENT_STEP");
    },
  },
};
</script>

<style scoped>
.clickable-alert {
  background-color: var(--red);
  color: white !important;
  font-weight: 700;
  cursor: pointer;
}
</style>
