<template>
  <v-container
    fill-height
    fluid
    :style="{
      backgroundColor: dark ? 'rgb(40, 40, 40)' : 'inherit',
    }"
  >
    <BlockablesNavbar :show="showNavDrawer" />
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-row>
          <v-col cols="12" class="pr-6 d-flex align-center justify-end">
            <v-btn
              v-if="!$ark.active"
              icon
              @click="$store.commit('USER/ACCOUNT_DIALOG_SET', true)"
            >
              <v-icon :color="dark ? '#fafafa' : $colors.blue">
                {{ icons.account }}
              </v-icon>
            </v-btn>
            <v-btn icon @click="showNavDrawer += 1" class="pl-2">
              <v-icon
                aria-label="Show menu"
                tabindex="-1"
                :color="dark ? '#fafafa' : $colors.blue"
              >
                {{ icons.menu }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-img
              v-if="$ark.active"
              :aspect-ratio="1 / 1"
              height="100"
              alt="Blockables logo"
              contain
              :src="require('@/assets/blockables-arkadium.png')"
              transition="scale-transition"
            />
            <v-img
              v-else
              :aspect-ratio="1 / 1"
              height="100"
              alt="Blockables logo"
              contain
              :src="require('@/assets/blockables-square.png')"
              transition="scale-transition"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex align-center justify-center"
            :class="author ? 'pb-2' : ''"
          >
            <span
              :style="{
                color: dark ? 'white' : 'black !important',
              }"
            >
              <h1 class="text-center">Blockables</h1>
              <p
                class="text-center"
                :class="author ? 'mb-0' : ''"
                style="line-height: 1.2"
              >
                <!-- <p class="text-center"> -->
                <strong>#{{ puzzleNumber }}:</strong>
                {{
                  $route.name == "Home"
                    ? todayHumanReadable
                    : humanReadable($route.params.d)
                }}
                <template v-if="author">
                  <br />
                  <em style="font-size: 12px">By {{ author }}</em>
                </template>
              </p>
            </span>
          </v-col>
        </v-row>
        <v-row v-if="wins.includes(puzzleNumber)">
          <v-col cols="12" class="py-0 d-flex align-center justify-center">
            <p
              class="mb-0"
              :style="{
                color: dark ? 'white' : 'black !important',
              }"
            >
              Tomorrow's puzzle in:
              <b>{{ timeRemaining }}...</b>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-btn
              block
              :color="$colors.blue"
              class="white--text"
              :dark="dark"
              :disabled="wins.includes(puzzleNumber)"
              @click="play()"
            >
              {{
                wins.includes(puzzleNumber)
                  ? "Puzzle solved!"
                  : $route.name == "Home"
                  ? "Play Today's Puzzle"
                  : `Play Puzzle #${puzzleNumber}`
              }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="10" class="d-flex align-center justify-center">
            <PlayPastPuzzlesToggle />
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col cols="6" class="d-flex align-center justify-center">
            <v-btn
              v-if="$ark.active"
              plain
              :dark="dark"
              @click.native="goToTutorial"
            >
              Tutorial
            </v-btn>
            <v-btn
              v-else
              plain
              :dark="dark"
              @click.native="
                $store.commit('USER/INSTRUCTIONS_DIALOG_SET', true)
              "
            >
              Instructions
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-center">
            <v-btn
              plain
              :dark="dark"
              @click="$store.commit('USER/SETTINGS_DIALOG_SET', true)"
            >
              Settings
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import BlockablesNavbar from "./BlockablesNavbar.vue";
import PlayPastPuzzlesToggle from "./buttons/PlayPastPuzzlesToggle.vue";

import { mdiAccount, mdiMenu } from "@mdi/js";

export default {
  components: {
    BlockablesNavbar,
    PlayPastPuzzlesToggle,
  },
  data: () => ({
    icons: {
      account: mdiAccount,
      menu: mdiMenu,
    },
    pppActive: false,
    showNavDrawer: 0,
  }),
  computed: {
    allPuzzles() {
      return true;
    },
    author() {
      return this.$store.state.PUZZLE.metadata.author.name;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    puzzleNumber() {
      return this.getOrdinal(
        this.$route.name == "Home" ? this.today : this.$route.params.d
      );
    },
    timed() {
      return !this.$store.state.USER.settings.untimed;
    },
    timeRemaining() {
      return this.$store.getters["USER/TIME_REMAINING"];
    },
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
    todayHumanReadable() {
      return this.humanReadable();
    },
    user() {
      if (this.$ark.active) return null;
      try {
        let localStorageUser = JSON.parse(
          localStorage.getItem("sb-cterwiozjbjdeoazbzdd-auth-token")
        );
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = this.$store.state.sb_user;
        return storeUser || localStorageUser || null;
      } catch {
        return null;
      }
    },
    wins() {
      try {
        if (this.user) {
          let wins = this.$store.state.USER.stats.map(s => s.ordinal);
          if (!wins) return [];
          return wins;
        }
        let wins = localStorage.getItem("blockables-ark-wins");
        if (!wins) return [];
        return JSON.parse(wins);
      } catch {
        return [];
      }
    },
  },
  async mounted() {
    if (this.user) {
      this.$store.dispatch("USER/GET_STATS_FROM_DB", this.user);
    }
  },
  methods: {
    getOrdinal(d) {
      let o = null;
      try {
        let debutDate = process.env.VUE_APP_DEBUT_DATE;
        let then = debutDate.split("-");
        then = new Date(then[0], then[1] - 1, then[2]);
        let now = d.split("-");
        now = new Date(now[0], now[1] - 1, now[2]);
        o = Math.round((now - then) / (1000 * 60 * 60 * 24)) + 1;
      } catch {
        o = null;
      }
      return o;
    },
    goToTutorial() {
      this.$router.push("/how-to-play");
    },
    humanReadable(d) {
      if (!d) {
        d = new Date(
          new Date(this.today).toLocaleString("en-US", {
            timeZone: "GMT",
          })
        );
      } else {
        d = new Date(
          new Date(d).toLocaleString("en-US", {
            timeZone: "GMT",
          })
        );
      }

      let dd = d.getDate();

      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][d.getMonth()];

      return `${month} ${dd}${this.nth(dd)}, ${d.getFullYear()}`;
    },
    nth(d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
    play() {
      this.$analytics.logEvent("game_start", { timed: this.timed });
      if (this.$ark.active) {
        this.$ark.api.handleGameStart();
      }
      // this.$store.commit("USER/TIMED_SET", method == "timed");
      this.$store.commit("USER/GAME_START", true);
      this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", false);
    },
  },
};
</script>

<style scoped>
.sticky-keyboard {
  position: sticky;
  position: -webkit-sticky;
  height: 152px;
  bottom: 0;
  z-index: 4;
  background-color: #ececec;
}

.affix-top {
  align-items: flex-start;
  justify-content: flex-start;
  position: sticky; /* Or 'fixed' if you need it fixed at the top */
  top: 0;
  z-index: 10; /* Ensure it's on top if needed */
}
</style>
